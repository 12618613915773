import { useTranslation } from "react-i18next";

export const ContactUsButton = () => {

  const { t } = useTranslation();

  const onClickScroll = () => {
    const element = document.getElementById("contact");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <button onClick={onClickScroll} className="flex flex-col w-[250px] items-start justify-center gap-[60px] relative flex-[0_0_auto]">
      <div className="hover:bg-blue-500  flex h-14 items-center justify-center gap-2.5 p-2.5 relative self-stretch w-full rounded-[28px] bg-[#0161E3]">
        <div className="relative w-fit font-semibold text-white text-xl tracking-[0] leading-[26px] whitespace-nowrap">
          {t('contact-us-button')}
        </div>
      </div>
    </button>
  );
};
