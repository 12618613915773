import React, { useEffect } from "react";
import ReactDOM from "react-dom";

import x from "../../../assets/svg/x.svg";

const Modal = ({
  isOpen,
  onClose,
  className = "bg-white rounded-lg shadow-lg p-6 relative",
  children,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => (document.body.style.overflow = "unset");
  }, [isOpen]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div
        className={className}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="absolute top-4 right-2 md:top-[30px] right-[32px] text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          <img className="relative w-6 h-6 md:w-8 md:h-8" alt="close" src={x} />
        </button>
        {children}
      </div>
    </div>,
    document.getElementById("overlay")
  );
};

export default Modal;
