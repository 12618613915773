import { useTranslation } from "react-i18next";
import FadeUp from "../shared/FadeUp";

export const ContactUs = () => {
  const { t } = useTranslation();

  async function SubmitEmail(e) {
    e.preventDefault();
    
    const response = await fetch(process.env.REACT_APP_RESEND_URL, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_RESEND_API_KEY}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        from: "Acme <onboarding@resend.dev>",
        to: ["monica.chapa@alluxi.com"],
        subject: "hello world",
        html: "<p>it works!</p>",
      }),
    });

    const data = await response.json();
    console.log(data);
  }

  return (
    <div
      id="contact"
      className="pt-[111px] w-full flex justify-start md:gap-[50px] px-10 xl:px-28"
    >
      <form
        onSubmit={(e) => SubmitEmail(e)}
        className="md:flex flex-col w-auto items-start gap-[70px] relative md:px-7"
      >
        <div className="flex flex-col items-start gap-10 relative w-auto flex-[0_0_auto]">
          <FadeUp>
            <p className="font-bold text-[40px] text-[#5E51E9] text-center md:text-left leading-[48px]">
              {t("contact-us-title")}
            </p>
          </FadeUp>

          <div className="w-full inline-flex flex-col items-start gap-4 relative flex-[0_0_auto]">
            <input
              placeholder={t("name")}
              className="flex w-full text-[#9a9a9a] text-lg bg-transparent items-center gap-2.5 px-[19px] py-3.5 relative flex-[0_0_auto] rounded-[10px] overflow-hidden border border-solid border-[#d9d9d9]"
            />
            <input
              placeholder={t("phone")}
              className="flex w-full text-[#9a9a9a] text-lg bg-transparent items-center gap-2.5 px-[19px] py-3.5 relative flex-[0_0_auto] rounded-[10px] overflow-hidden border border-solid border-[#d9d9d9]"
            />
            <input
              placeholder={t("email")}
              type="email"
              className="flex w-full text-[#9a9a9a] text-lg bg-transparent items-center gap-2.5 px-[19px] py-3.5 relative flex-[0_0_auto] rounded-[10px] overflow-hidden border border-solid border-[#d9d9d9]"
            />
            <textarea
              placeholder={t("message")}
              className="flex items-start gap-2.5 px-[19px] py-[17px] relative rounded-[10px] overflow-hidden border border-solid border-[#d9d9d9] text-[#9a9a9a] text-lg bg-transparent relative  w-full h-[198px] mt-[-1.00px] [font-family:'Poppins',Helvetica] font-normal text-[#9a9a9a] text-lg tracking-[0] leading-[normal]"
            />
          </div>
        </div>
        <FadeUp>
          <button
            type="submit"
            className="flex flex-col w-[250px] items-start justify-center gap-[60px] relative flex-[0_0_auto] mt-6 md:mt-0"
          >
            <div className="hover:bg-blue-500  flex h-14 items-center justify-center gap-2.5 p-2.5 relative w-full rounded-[28px] bg-[#0161E3]">
              <div className="relative w-fit font-semibold text-white text-xl tracking-[0] leading-[26px] whitespace-nowrap">
                {t("submit-now")}
              </div>
            </div>
          </button>
        </FadeUp>
      </form>
      <img
        className="hidden md:block md:relative w-[400.8px] h-[450px] xl:w-[519.8px] xl:h-[650px]"
        alt="Vecteezy portrait of"
        src="https://c.animaapp.com/dbTYVPsm/img/vecteezy-portrait-of-happy-friendly-male-indian-latin-doctor-med.png"
      />
    </div>
  );
};
