import { ContactUsButton } from "../shared/ContactUsButton";

import past from "../../assets/img/testimonials/past.png";
import future from "../../assets/img/testimonials/future.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";
import FadeUp from "../shared/FadeUp";

export const Testimonials = () => {
  const { t, i18n } = useTranslation();

  const language = i18n.language;

  const testimonials = [
    {
      description: {
        en: "In September 2021, I had a consultation with Dr. Z and began my journey. I followed his pre-op diet, booked my flights, and I was ready for surgery after struggling with weight loss and severe chronic gastritis all my life. Everything went smoothly. My highest weight was 298, surgery weight was 252, and now I’m 134.\n In 2023, I returned for another procedure, and Dr. Z took the time to take a picture with me and catch up. In 2024, I’ve had some unrelated health issues, but Dr. Z has been helping me figure them out since they’re GI-related. He’s hands down one of the best doctors I’ve ever had.",
        "es-US":
          "En septiembre de 2021, tuve una consulta con el Dr. Z y comencé mi viaje. Seguí su dieta preoperatoria, reservé mis vuelos y estaba listo para la cirugía después de luchar con la pérdida de peso y una gastritis crónica severa toda mi vida. Todo salió bien. Mi peso máximo fue 298, el peso al momento de la cirugía fue 252 y ahora estoy en 134.\n En 2023, regresé para otro procedimiento, y el Dr. Z se tomó el tiempo para tomarse una foto conmigo y ponernos al día. En 2024, he tenido algunos problemas de salud no relacionados, pero el Dr. Z ha estado ayudándome a resolverlos ya que son problemas gastrointestinales. Sin lugar a dudas, es uno de los mejores médicos que he tenido.",
      },
      name: "Christina Marie",
      author: "Lorem ipsum",
      past_img: past,
      future_img: future,
    },
    {
      description: {
        en: "In September 2021, I had a consultation with Dr. Z and began my journey. I followed his pre-op diet, booked my flights, and I was ready for surgery after struggling with weight loss and severe chronic gastritis all my life. Everything went smoothly. My highest weight was 298, surgery weight was 252, and now I’m 134.\n In 2023, I returned for another procedure, and Dr. Z took the time to take a picture with me and catch up. In 2024, I’ve had some unrelated health issues, but Dr. Z has been helping me figure them out since they’re GI-related. He’s hands down one of the best doctors I’ve ever had.",
        "es-US":
          "En septiembre de 2021, tuve una consulta con el Dr. Z y comencé mi viaje. Seguí su dieta preoperatoria, reservé mis vuelos y estaba listo para la cirugía después de luchar con la pérdida de peso y una gastritis crónica severa toda mi vida. Todo salió bien. Mi peso máximo fue 298, el peso al momento de la cirugía fue 252 y ahora estoy en 134.\n En 2023, regresé para otro procedimiento, y el Dr. Z se tomó el tiempo para tomarse una foto conmigo y ponernos al día. En 2024, he tenido algunos problemas de salud no relacionados, pero el Dr. Z ha estado ayudándome a resolverlos ya que son problemas gastrointestinales. Sin lugar a dudas, es uno de los mejores médicos que he tenido.",
      },
      name: "Name",
      author: "Lorem ipsum",
      past_img: past,
      future_img: future,
    },
    {
      description: {
        en: "In September 2021, I had a consultation with Dr. Z and began my journey. I followed his pre-op diet, booked my flights, and I was ready for surgery after struggling with weight loss and severe chronic gastritis all my life. Everything went smoothly. My highest weight was 298, surgery weight was 252, and now I’m 134.\n In 2023, I returned for another procedure, and Dr. Z took the time to take a picture with me and catch up. In 2024, I’ve had some unrelated health issues, but Dr. Z has been helping me figure them out since they’re GI-related. He’s hands down one of the best doctors I’ve ever had.",
        "es-US":
          "En septiembre de 2021, tuve una consulta con el Dr. Z y comencé mi viaje. Seguí su dieta preoperatoria, reservé mis vuelos y estaba listo para la cirugía después de luchar con la pérdida de peso y una gastritis crónica severa toda mi vida. Todo salió bien. Mi peso máximo fue 298, el peso al momento de la cirugía fue 252 y ahora estoy en 134.\n En 2023, regresé para otro procedimiento, y el Dr. Z se tomó el tiempo para tomarse una foto conmigo y ponernos al día. En 2024, he tenido algunos problemas de salud no relacionados, pero el Dr. Z ha estado ayudándome a resolverlos ya que son problemas gastrointestinales. Sin lugar a dudas, es uno de los mejores médicos que he tenido.",
      },
      name: "Name",
      author: "Lorem ipsum",
      past_img: past,
      future_img: future,
    },
    {
      description: {
        en: "In September 2021, I had a consultation with Dr. Z and began my journey. I followed his pre-op diet, booked my flights, and I was ready for surgery after struggling with weight loss and severe chronic gastritis all my life. Everything went smoothly. My highest weight was 298, surgery weight was 252, and now I’m 134.\n In 2023, I returned for another procedure, and Dr. Z took the time to take a picture with me and catch up. In 2024, I’ve had some unrelated health issues, but Dr. Z has been helping me figure them out since they’re GI-related. He’s hands down one of the best doctors I’ve ever had.",
        "es-US":
          "En septiembre de 2021, tuve una consulta con el Dr. Z y comencé mi viaje. Seguí su dieta preoperatoria, reservé mis vuelos y estaba listo para la cirugía después de luchar con la pérdida de peso y una gastritis crónica severa toda mi vida. Todo salió bien. Mi peso máximo fue 298, el peso al momento de la cirugía fue 252 y ahora estoy en 134.\n En 2023, regresé para otro procedimiento, y el Dr. Z se tomó el tiempo para tomarse una foto conmigo y ponernos al día. En 2024, he tenido algunos problemas de salud no relacionados, pero el Dr. Z ha estado ayudándome a resolverlos ya que son problemas gastrointestinales. Sin lugar a dudas, es uno de los mejores médicos que he tenido.",
      },
      name: "Name",
      author: "Lorem ipsum",
      past_img: past,
      future_img: future,
    },
  ];

  return (
    <div
      id="testimonials"
      className="flex flex-col items-center mt-[111px] bg-[#edf3fc] p-7 md:p-20 px-12 xl:px-52"
    >
      <div className="flex flex-col items-center relative w-full flex-[0_0_auto]">
        <div className="flex items-center justify-center gap-2.5 relative w-full flex-[0_0_auto]">
          <FadeUp>
            <div className="font-semibold text-[40px] text-[#5E51E9]">
              {t("patient-testimonials")}
            </div>
          </FadeUp>
        </div>

        <div className="mt-12 md:mt-[60px] w-full">
          <Swiper
            modules={[Autoplay, Pagination, Navigation]}
            spaceBetween={30}
            slidesPerView={1}
            navigation
            autoplay={{ delay: 9000, disableOnInteraction: false }}
            pagination={{ clickable: true }}
            className="flex flex-col justify-center items-center bg-white w-full  rounded-[29px] shadow-xl"
          >
            {testimonials.map((item, index) => {
              const description =
                item.description[language] || item.description["en"];
              return (
                <SwiperSlide key={index}>
                  <div className="px-4 md:px-7 py-10 mb-8">
                    <div className="flex flex-col items-center max-w-full ">
                      <div className="md:flex md:space-x-8 space-y-8 md:space-y-0">
                        <div className="relative w-full flex  items-center md:flex-row justify-center md:justify-end space-y-8 md:space-y-8">
                          <img
                            loading="lazy"
                            srcSet={item.past_img}
                            className="w-[150px] md:w-[247px]  md:h-[363px] border border-gray-100 border-3 rounded-[29px]"
                            alt={"past" + index}
                          />
                          <img
                            loading="lazy"
                            srcSet={item.future_img}
                            className="w-[150px] md:w-[247px] md:h-[363px] border border-gray-100 border-3  md:mt-[64px] md:-ml-[24px] rounded-[29px]"
                            alt={"future" + index}
                          />
                        </div>

                        <div className="flex w-full flex-col">
                          <div className="space-y-4">
                            {description.split("\n").map((line, index) => (
                              <p
                                className="text-left text-base leading-6 text-neutral-800 max-md:max-w-full space-y-6"
                                key={index}
                              >
                                {line}
                              </p>
                            ))}
                          </div>
                          <div className="flex gap-4 items-center self-start mt-2 text-center">
                            <div className="flex flex-col items-start self-stretch my-auto">
                              <div className="text-lg font-medium text-slate-800">
                                {item.name}
                              </div>
                              <div className="mt-1 text-base text-indigo-600">
                                {item.author}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>

        <div className="mt-[70px]">
          <FadeUp>
            <ContactUsButton />
          </FadeUp>
        </div>
      </div>
    </div>
  );
};
