import { Header } from "../Header";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

import DrZavalza from "../../assets/img/Dr-Zavalza.png";
import DrMario from "../../assets/img/Dr-Mario.png";
import DrKarla from "../../assets/img/Dra-Karla.png";

import stethoscope from "../../assets/svg/stethoscope.svg";

import { ContactUsButton } from "../shared/ContactUsButton";
import { Footer } from "../Footer";
import { OurServices } from "../OurServices";
import { OurDoctors } from "../OurDoctors";
import { Testimonials } from "../Testimonials";
import { ContactUs } from "../ContactUs";
import { About } from "../About";
import { useTranslation } from "react-i18next";
import FadeUp from "../shared/FadeUp";

export const ZplendidLanding = () => {
  const { i18n } = useTranslation();

  return (
    <div>
      <div className="mx-7">
        <Header />
        <div id="home" className="md:mx-10 xl:mx-28">
          <FadeUp>
            <Slider language={i18n.language} />
          </FadeUp>

          {/*ABOUT*/}
          <FadeUp>
            <About />
          </FadeUp>
        </div>
      </div>
      {/*SERVICES*/}
      <OurServices />
      {/*OUR DOCTORS*/}
      <OurDoctors />
      {/*PATIENT TESTIMONIALS*/}
      <Testimonials />
      {/*CONTACT US*/}
      <ContactUs />
      <div className="mt-[111px]">
        <Footer />
      </div>
    </div>
  );
};

export const Slider = ({ language }) => {
  const data = [
    {
      title: {
        en: "Accuracy and Clinical Excellence\n in Bariatric Surgery",
        "es-US": "Precisión y Excelencia Clínica\n en Cirugía Bariátrica",
      },
      description: {
        en: "ENGLISH Lorem ipsum dolor sit amet consectetur. Auctor rutrum nisl dui nec lacus egestas amet vivamus nunc. Sapien ipsum etiam mauris enim erat quam neque faucibus tristique.",
        "es-US":
          "SPANISH Lorem ipsum dolor sit amet consectetur. Auctor rutrum nisl dui nec lacus egestas amet vivamus nunc. Sapien ipsum etiam mauris enim erat quam neque faucibus tristique.",
      },
      img: DrZavalza,
      name: "Francisco Zavalza MD",
      position: {
        en: "Bariatric Surgeon",
        "es-US": "Cirujano bariátrico",
      },
    },
    {
      title: {
        en: "Precision and Outstanding Care\n in Plastic Surgery",
        "es-US": "Precisión y Cuidado Excepcional\n en Cirugía Plástica",
      },
      description: {
        en: "ENGLISH Lorem ipsum dolor sit amet consectetur. Auctor rutrum nisl dui nec lacus egestas amet vivamus nunc. Sapien ipsum etiam mauris enim erat quam neque faucibus tristique.",
        "es-US":
          "SPANISH Lorem ipsum dolor sit amet consectetur. Auctor rutrum nisl dui nec lacus egestas amet vivamus nunc. Sapien ipsum etiam mauris enim erat quam neque faucibus tristique.",
      },
      img: DrMario,
      name: "Dr. Mario Mora",
      position: {
        en: "Plastic Surgeon",
        "es-US": "Cirujano Plástico",
      },
    },
    {
      title: {
        en: "Expertise and Advanced Treatment\n in General and Bariatric Surgery",
        "es-US":
          "Experiencia y Tratamiento Avanzado\n en Cirugía General y Bariátrica",
      },
      description: {
        en: "ENGLISH Lorem ipsum dolor sit amet consectetur. Auctor rutrum nisl dui nec lacus egestas amet vivamus nunc. Sapien ipsum etiam mauris enim erat quam neque faucibus tristique.",
        "es-US":
          "SPANISH Lorem ipsum dolor sit amet consectetur. Auctor rutrum nisl dui nec lacus egestas amet vivamus nunc. Sapien ipsum etiam mauris enim erat quam neque faucibus tristique.",
      },
      img: DrKarla,
      name: "Dra. Karla Rosales",
      position: {
        en: "General Surgeon",
        "es-US": "Cirujana General",
      },
    },
  ];

  return (
    <Swiper
      modules={[Autoplay, Pagination, Navigation]}
      spaceBetween={30}
      slidesPerView={1}
      navigation
      autoplay={{ delay: 9000, disableOnInteraction: false }}
      pagination={{ clickable: true }}
      className="mySwiper"
    >
      {data.map((item, index) => (
        <SwiperSlide key={"Slide" + index}>
          {" "}
          <div className="md:flex justify-between items-center space-x-0 md:space-x-6 lg:space-x-6">
            <div className="md:w-1/2 space-y-10">
              <div className="space-y-6">
                <p className="relative text-left text-[#0161E3] leading-10 md:leading-[48px] font-bold mt-[-1.00px] text-[30px] md:text-[40px]">
                  {item.title[language]}
                </p>
                <p className="text-left font-body-regular font-[number:var(--body-regular-font-weight)] text-variable-collection-RGB-25-27-60-texto text-[length:var(--body-regular-font-size)] leading-[var(--body-regular-line-height)] relative tracking-[var(--body-regular-letter-spacing)] [font-style:var(--body-regular-font-style)]">
                  {item.description[language]}
                </p>
              </div>
              <div className="md:block hidden">
                <ContactUsButton />
              </div>
            </div>
            <div className="relative flex md:w-1/2 justify-center">
              <div className="relative w-[449px] h-[452px]">
                <div className="mt-4 md:mt-0">
                  <div className="relative h-[300px] w-[300px] m-auto lg:w-full lg:h-[427px] rounded-[213.26px] [background:linear-gradient(180deg,rgb(1,97,227)_0%,rgb(94,81,233)_100%)] overflow-hidden">
                    <img
                      className="absolute md:w-[427px] h-[435px] top-0 left-0 object-cover"
                      alt="Mask group"
                      src={item.img}
                    />
                  </div>
                </div>
                <div className="inline-flex flex-col items-start gap-2.5 px-4 py-3.5 absolute top-[308px] md:top-[378px] left-0 md:left-auto md:right-0 md:right-auto md:left-[11px] bg-white rounded-lg shadow-[0px_12px_32px_#3e7ff014]">
                  <div className="inline-flex items-start gap-4 relative flex-[0_0_auto]">
                    <div className="flex w-8 h-8 items-center justify-around gap-2.5 p-1 relative">
                      <div className="absolute w-8 h-8 top-0 left-0 rounded-2xl shadow-[inset_0px_4px_4px_#0000001f,0px_4px_19px_#1578f233] [background:linear-gradient(180deg,rgb(1,97,227)_0%,rgb(94,81,233)_100%)]" />
                      <img
                        className="relative w-6 h-6"
                        alt="Badge obat"
                        src={stethoscope}
                      />
                    </div>
                    <div className="inline-flex flex-col items-start gap-0.5 relative flex-[0_0_auto]">
                      <div className="relative w-fit mt-[-1.00px] [font-family:'Open_Sans',Helvetica] font-semibold text-dark text-base tracking-[0] leading-6 whitespace-nowrap">
                        {item.name}
                      </div>
                      <div className="relative w-fit font-body-extra-small-regular font-[number:var(--body-extra-small-regular-font-weight)] text-second text-[length:var(--body-extra-small-regular-font-size)] tracking-[var(--body-extra-small-regular-letter-spacing)] leading-[var(--body-extra-small-regular-line-height)] whitespace-nowrap [font-style:var(--body-extra-small-regular-font-style)]">
                        {item.position[language]}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full justify-center flex my-[80px] md:hidden">
                  <ContactUsButton />
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
