import { useTranslation } from "react-i18next";

export const About = () => {
  const { t } = useTranslation();

  return (
    <div
      id="about"
      className="md:mx-24 flex flex-col items-center gap-10 pt-[120px]"
    >
      <div className="flex flex-col items-center gap-6 relative flex-[0_0_auto]">
        <div className="text-[#5E51E9] relative mt-[-1.00px] [font-family:'Open_Sans',Helvetica] font-bold text-variable-collection-RGB-94-81-233 text-[40px] text-center tracking-[0] leading-[48px]">
          {t("about-zplendid")}
        </div>
        <p className="relative uppercase  [font-family:'Open_Sans',Helvetica] font-semibold text-variable-collection-RGB-25-27-60-texto text-2xl text-center tracking-[0] leading-10">
          {t("about-desc")}
        </p>
      </div>
      <div className="flex md:h-[459px] h-[359px] items-center justify-center gap-2.5 relative w-full bg-[url(https://c.animaapp.com/dbTYVPsm/img/placeholder---video.svg)] bg-cover bg-[50%_50%]"></div>
    </div>
  );
};
