import { useTranslation } from "react-i18next";

import location from "../../assets/svg/location.svg";
import phone from "../../assets/svg/phone.svg";
import email from "../../assets/svg/email.svg";

import facebook from "../../../src/assets/svg/social-media/facebook-white.svg";
import instagram from "../../../src/assets/svg/social-media/instagram-white.svg";
import youtube from "../../../src/assets/svg/social-media/youtube-white.svg";
import linkedin from "../../../src/assets/svg/social-media/linkedin-white.svg";
import x from "../../../src/assets/svg/social-media/x-white.svg";
import tiktok from "../../../src/assets/svg/social-media/tiktok-white.svg";

export const Footer = () => {
  const { t } = useTranslation();

  const footer_data = [
    {
      title: t("company-info"),
      data: [
        {
          description: "Lorem ipsum",
        },
        {
          description: "Lorem ipsum",
        },
        {
          description: "Lorem ipsum",
        },
        {
          description: "Lorem ipsum",
        },
      ],
    },
    {
      title: "Info.",
      data: [
        {
          description: "Lorem ipsum",
        },
        {
          description: "Lorem ipsum",
        },
        {
          description: "Lorem ipsum",
        },
        {
          description: "Lorem ipsum",
        },
      ],
    },
    {
      title: "Info.",
      data: [
        {
          description: "info@Zplendid.com",
        },
        {
          description: "Tel: +560000000",
        },
        {
          description: "Lorem ipsum",
        },
        {
          description: "Lorem ipsum",
        },
      ],
    },
  ];

  const social_media = [
    {
      name: "Facebook",
      svg: facebook,
      href: "https://www.facebook.com/DrZavalza?sfnsn=scwspwa&mibextid=RUbZ1f",
    },
    {
      name: "Instagram",
      svg: instagram,
      href: "https://www.instagram.com/dr_zavalza?utm_source=qr&igsh=MXIzY3Fpd25zYTZ4bA%3D%3D",
    },
    {
      name: "Youtube",
      svg: youtube,
    },
    {
      name: "Linkedin",
      svg: linkedin,
    },
    {
      name: "X",
      svg: x,
    },
    {
      name: "Tik Tok",
      svg: tiktok,
      href: "https://www.tiktok.com/@drfranciscozavalza?_t=8p0OZgmaJks&_r=1",
    },
  ];

  return (
    <footer className="bg-[#191B3C] h-full bg-variable-collection-RGB-25-27-60-texto md:px-10 lg:px-40 md:py-16 py-7 px-7  text-white">
      <div className="md:flex justify-between pb-6 md:border-b border-white">
        <div className="w-full">
          <p className="md:italic md:font-light text-base md:text-left">
            Lorem ipsum dolor sit amet consectetur. <br />
            Augue vel posuere in ultricies in etiam maecenas <br />
            Varius nisl commodo nec ultrices.
          </p>
        </div>
        {/*MOBILE*/}
        <div className="mt-[18px] space-y-[18px] flex flex-col justify-center w-full items-center">
          <img className="w-5" alt="location" src={location}></img>
          <p>
            Lorem ipsum dolor sit amet consectetur. Elit amet duis venenatis
            arcu id purus tincidunt.
          </p>
          <img className="w-5" alt="phone" src={phone}></img>
          <p>Tel: +560000000</p>
          <img className="w-5" alt="email" src={email}></img>
          <p>info@Zplendid.com</p>
          <p className="font-semibold">Follow us</p>
          <div className="flex space-x-3">
            {social_media.map((item) => (
              <button
                key={"social media" + item.name}
                onClick={() => {
                  if (item.href) {
                    window.open(item.href);
                  }
                }}
              >
                <img
                  className="relative w-[29px] h-[29px]"
                  alt={item.name}
                  src={item.svg}
                />
              </button>
            ))}
          </div>
        </div>
        {/*DESKTOP*/}
        <div className="hidden md:flex justify-between w-full">
          {footer_data.map((item, index) => (
            <div key={"footer data" + index} className="text-left space-y-6">
              <p className="text-xl font-semibold">{item.title}</p>
              {item.data.map((data, index) => (
                <div key={"description" + index} className="space-y-4">
                  <p className="text-base font-light">{data.description}</p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <p className="hidden md:block pt-10 text-base font-light">
        Copyright © Zplendid 2024
      </p>
    </footer>
  );
};
