import { useState, useRef } from "react";

import DrZavalza from "../../assets/img/Dr-Zavalza.png";
import DrKarla from "../../assets/img/Dra-Karla.png";
import DrMario from "../../assets/img/Dr-Mario.png";
import certificate from "../../assets/img/certificate.png";

import arrowDown from "../../assets/svg/arrow-down.svg";
import arrowUp from "../../assets/svg/arrow-up.svg";
import arrowRight from "../../assets/svg/arrow-right.svg";

import slideLeft from "../../assets/svg/slide-left.svg";
import slideRight from "../../assets/svg/slide-right.svg";

import Modal from "../shared/Modal";
import { useTranslation } from "react-i18next";
import FadeUp from "../shared/FadeUp";

export const OurDoctors = () => {
  const { t, i18n } = useTranslation();

  const doctors = [
    {
      name: "Francisco Zavalza",
      position: "MD",
      description: {
        en: "Top ranked bariatric surgeon in Mexico that has conducted more than 10,000 surgeries for the control of obesity and metabolic diseases.",
        "es-US":
          "Cirujano bariátrico mejor clasificado en México, que ha realizado más de 10,000 cirugías para el control de la obesidad y enfermedades metabólicas.",
      },
      img: DrZavalza,
      documents: [
        {
          img: certificate,
          type: "img",
        },
        {
          img: certificate,
          type: "img",
        },
      ],
    },
    {
      name: "Dra. Karla Rosales",
      position: "MD",
      description: {
        en: "Surgeon with double board certification by the Mexican Council of General Surgery with 4 years of experience in bariatric surgery and more than 1000 surgeries performed, committed to the dignified and personalized treatment of the patient, as well as constant updating on relevant topics to achieve excellence in the operating room.",
        "es-US":
          "Cirujana con doble certificación por el Consejo Mexicano de Cirugía General, con 4 años de experiencia en cirugía bariátrica y más de 1000 cirugías realizadas, comprometido con el trato digno y personalizado del paciente, así como con la actualización constante en temas relevantes para lograr la excelencia en el quirófano.",
      },
      img: DrKarla,
      documents: [
        {
          img: certificate,
          type: "img",
        },
      ],
    },
    {
      name: "Dr. Mario Mora",
      position: "MD",
      description: {
        en: "Plastic Surgeon with Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
        "es-US":
          "Cirujano Plástico con Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
      },
      img: DrMario,
      documents: [
        {
          img: certificate,
          type: "img",
        },
      ],
    },
  ];
  return (
    <div id="doctors" className="flex flex-col items-center pt-[79px] ">
      <div className="flex flex-col items-center gap-[70px] relative  flex-[0_0_auto]">
        <div className="flex items-center justify-center gap-2.5 relative  w-full flex-[0_0_auto]">
          <FadeUp>
            <div className="font-semibold text-[40px] text-[#5E51E9]">
              {t("our-doctors")}
            </div>
          </FadeUp>
        </div>
        <FadeUp>
          <div className="md:flex flex-col justify-center w-full md:px-0 xl:px-28">
            <div className="grid md:gap-x-[30px] lg:grid-cols-2 xl:grid-cols-3 px-7 gap-y-[30px] md:space-y-0">
              {doctors.map((item, index) => (
                <DoctorCard key={'Doctor' + index} doctor={item} language={i18n.language} />
              ))}
            </div>
          </div>
        </FadeUp>
      </div>
    </div>
  );
};

const DoctorCard = ({ doctor, language }) => {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const textRef = useRef(null);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div
      className={`flex min-w-[380px] ${
        !isExpanded && " h-[595px]"
      } justify-center gap-2.5 px-[30px] py-[35px] relative flex-1 grow bg-white rounded-[30px] shadow-[4px_4px_24px_#abaec240] transition-all duration-300 ease-in-out`}
    >
      <DoctorModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        documents={doctor.documents}
      />
      <div className="flex flex-col w-[332px] items-center gap-[15px] relative ml-[-6.00px] mr-[-6.00px]">
        <div className="flex items-center justify-center gap-2.5 relative w-full flex-[0_0_auto]">
          <div className="inline-flex h-[276px] items-center gap-2.5 relative flex-[0_0_auto]">
            <div className="relative w-[225px] h-[225px] bg-[#edf3fc] rounded-full overflow-hidden">
              <img
                className="absolute inset-0 w-full h-full object-cover"
                alt="Mask group"
                src={doctor.img}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col w-[332px] items-center gap-[18px] relative flex-[0_0_auto]">
          <div className="inline-flex flex-col items-start gap-2 relative flex-[0_0_auto]">
            <div className="font-bold text-[32px] text-[#191B3C] text-center w-full">
              {doctor.name}
            </div>
            <div className="w-full text-center font-semibold text-2xl text-[#5E51E9]">
              {doctor.position}
            </div>
            <p
              ref={textRef}
              className={``}
              style={{
                display: isExpanded ? "" : "-webkit-box",
                WebkitLineClamp: isExpanded ? "none" : 3,
                WebkitBoxOrient: "vertical",
                overflow: isExpanded ? "visible" : "hidden",
                textOverflow: isExpanded ? "clip" : "ellipsis",
              }}
            >
              {doctor.description[language]}
            </p>
            {isExpanded && (
              <button
                onClick={openModal}
                className="uppercase flex w-full mt-6 justify-center items-center text-[#191B3C] text-lg font-bold space-x-1.5"
              >
                <p>{t("view-cv")}</p>
                <img
                  className="relative w-[18px] h-[18px]"
                  alt="Fluent ios arrow"
                  src={arrowRight}
                />
              </button>
            )}
          </div>
          <button onClick={handleClick}>
            <img
              className="relative w-8 h-8"
              alt="Fluent ios arrow"
              src={isExpanded ? arrowUp : arrowDown}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

const DoctorModal = ({ isModalOpen, closeModal, documents }) => {
  return (
    <Modal
      className="mx-6 md:mx-0 bg-[#EDF3FC] rounded-lg shadow-lg p-[30px] md:p-[70px] relative"
      isOpen={isModalOpen}
      onClose={closeModal}
    >
      <div className="flex space-x-5 items-center">
        <DocumentSlider documents={documents} />
      </div>
    </Modal>
  );
};

const DocumentSlider = ({ documents }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? documents.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === documents.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="flex space-x-5 items-center">
      {documents.length > 1 && (
        <img
          className="relative w-8 h-8 cursor-pointer"
          alt="slideLeft"
          src={slideLeft}
          onClick={handlePrev}
        />
      )}
      <img
        className="md:w-[632px] md:h-[424px]"
        src={documents[currentIndex].img}
        alt={`Certificate ${currentIndex + 1}`}
      />
      {documents.length > 1 && (
        <img
          className="relative w-8 h-8 cursor-pointer"
          alt="slideRight"
          src={slideRight}
          onClick={handleNext}
        />
      )}
    </div>
  );
};
